import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useLocalStorage } from 'react-use';
import { useConfigsQuery } from '~/queries/commons/getConfigAllQuery';
import { useAuthContext } from '~/contexts/AuthContext';
import { message } from 'antd';
import { DEFAULT_CHANNEL } from '~/modules/home/components/DashBoardOrders/DashBoardOrders';

declare var window: any;

const GlobalContext = React.createContext({
  orderStatuses: [],
  paymentMethods: [],
  wards: [],
  districts: [],
  cities: [],
  shippingMethods: [],
  stores: [],
  shippingProviders: [],
  slaStatusOverdues: [],
  orderCancelCodes: [],
  allConfig: {},
  employeeRoles: [],
  orderRefundMethods: [],
  bankAccountProviders: [],
  settings: {
    enableNotice: true,
  },
  orderTags: [],
  employeePermissionActions: [],
  syncStockEvents: [],
  fulfillErrorTypes: [],
  orderOperationTypes: [],
  orderDeliveryTypes: [],
  tenants: {},
  saleChannels: [],
  saleChannel: DEFAULT_CHANNEL,
  setSaleChannel: (value: any) => {},
  tenant: null,
  setTenant: (value: any) => {},
  operationType: null,
  setOperationType: (value: any) => {},
  znsTemplates: [],
  loading: false,
  setLoading: (value: any) => {},
});

const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [version, setVersion] = useLocalStorage('versionConfig');

  const [settingsCache, setSettingsStorage] = useLocalStorage<any>('settings');
  const [tenant, setTenant] = useState<any>(null);
  const [saleChannel, setSaleChannel] = useState<any>(DEFAULT_CHANNEL);
  const [operationType, setOperationType] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);
  const [settings, setSettings] = useState(
    Object.keys(settingsCache || {}) ? { ...settingsCache } : { enableNotice: true }
  );

  const [configStore, setConfigStore] = useLocalStorage('configs');
  const [config, setConfig] = useState<any>(configStore ? JSON.parse(configStore as string) : {});
  const { isAuth } = useAuthContext();
  const { data, refetch } = useConfigsQuery(version ? JSON.parse(version as string) : null, {
    enabled: false,
  });

  console.log(config, 'config');

  useEffect(() => {
    if (data && !data.error) {
      if (Object.keys(data).length) {
        window.scConfig = config;
        setConfig(data?.allConfig);
        window.sc_config = data?.allConfig;
        setConfigStore(JSON.stringify(data?.allConfig));
        setVersion(JSON.stringify(data?.version));
      }
    }
  }, [data]);

  useEffect(() => {
    if (isAuth) {
      refetch();
    }
  }, [isAuth]);

  useEffect(() => {
    setSettingsStorage({ ...settings });
  }, [setSettingsStorage, settings]);

  const handleSetSetting = useCallback((settings) => {
    if (settings.enableNotice) {
      message.success('Đã bật thông báo');
    } else {
      message.warning('Đã tắt thông báo');
    }
    setSettings({ ...settings });
  }, []);

  const handleSetLoading = useCallback((value: any) => {
    setLoading(value);
  }, []);

  window.scConfig = config;

  const valueContext: any = useMemo(
    () => ({
      setConfig,
      setVersion,
      setConfigStore, 
      setTenant,
      setSaleChannel,
      onSetSettings: handleSetSetting,
      setLoading: handleSetLoading,
      ...config,
      loading,
      settings,
      allConfig: config,
      tenant,
      saleChannel,
      operationType,
      setOperationType,
    }),
    [setVersion, setConfigStore, setTenant, setSaleChannel, handleSetSetting, config, settings, tenant, saleChannel, operationType, setOperationType, loading, handleSetLoading]
  );

  return <GlobalContext.Provider value={valueContext}>{children}</GlobalContext.Provider>;
};

export default GlobalProvider;

export const useGlobalContext = () => useContext(GlobalContext);
