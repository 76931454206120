import { LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { AutoComplete, Image, Input, SelectProps } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { fallbackImage } from '~/contants/commons';
import { useSearchProductVariants } from '~/queries/product/useSearchProductVariants';

const renderItem = (data: any) => {
  return (
    <div className="flex items-center" key={data?.name}>
      <Image
        fallback={fallbackImage}
        preview={false}
        style={{ width: 40 }}
        src={data?.image || 'error'}
      />
      <div className="flex flex-col">
        <span className="font-medium mx-2">{data?.name}</span>
        <span className="text-xs text-gray-500 ml-2">{data?.barcode}</span>
      </div>
    </div>
  );
};

type Props = {
  onSelectProduct?: (value: any, product: Product, option: any) => void;
  onClear?: () => void;
  ref?: any;
  value?: string;
  [key: string]: any;
  inputPlaceholder?: string;
};

const ProductSelection: React.FC<Props> = forwardRef(
  ({ onSelectProduct, value: valueInitial, onClear, inputPlaceholder, ...rests }, ref: any) => {
    const [options, setOptions] = useState<SelectProps<object>['options']>([]);
    const [value, setValue] = useState<string>('');
    const [keyword, setKeyword] = useState('');

    const [] = useDebounce(
      () => {
        fetchEmployee();
      },
      500,
      [keyword]
    );

    const {
      data: response,
      isFetched,
      isFetching,
      refetch: fetchEmployee,
    } = useSearchProductVariants(
      {
        keyword: valueInitial || keyword,
        pageIndex: 1,
        limit: 20,
      },
      { enabled: false }
    );

    const data = useMemo(() => response?.list || [], [response]);

    useEffect(() => {
      if (!valueInitial) return;
      const employeeByName = data?.find((dt: any) => {
        return String(dt?.barcode)?.trim() === String(valueInitial)?.trim();
      });
      if (employeeByName) {
        setValue(employeeByName?.name + ' - ' + employeeByName?.barcode);
      }
    }, [data, valueInitial]);

    useEffect(() => {
      fetchEmployee();
    }, []);

    useEffect(() => {
      if (isFetched && !data.length) {
        setOptions([
          {
            value: '',
            label: <span>Không tìm thấy sản phẩm</span>,
          },
        ]);
      } else {
        const dataTransform = data?.map((data: any) => ({
          value: data.name + ' - ' + data.barcode,
          label: renderItem(data),
        }));

        setOptions(dataTransform || []);
      }
    }, [data, valueInitial]);

    useImperativeHandle(ref, () => ({
      reset: () => setValue(''),
    }));

    const handleSearch = (value: string) => {
      setValue('');
      setKeyword(value);
    };

    const handleSelect = (value: any, option: any) => {
      setKeyword('');
      const product = data.find((dt: any) => {
        return (
          String(dt?.barcode)?.trim() ===
          String(value?.split('-')?.[value?.split('-')?.length - 1])?.trim()
        );
      });
      if (product) {
        setValue(product.name + ' - ' + product.barcode);
        onSelectProduct?.(product?.barcode, product, option);
      }
    };

    return (
      <>
        <AutoComplete
          dropdownMatchSelectWidth={252}
          className="w-100"
          options={options}
          allowClear
          onSelect={handleSelect}
          onClear={() => {
            handleSelect(null, null);
            onClear?.();
          }}
          onSearch={handleSearch}
          value={keyword || value}
          {...rests}
        >
          <Input
            suffix={isFetching ? <LoadingOutlined /> : <SearchOutlined />}
            className="w-100"
            placeholder={inputPlaceholder || "Tìm kiếm sản phẩm ..."}
          />
        </AutoComplete>
      </>
    );
  }
);

export default ProductSelection;
