import { StopOutlined } from '@ant-design/icons';
import { ConfigProvider } from 'antd';
import 'antd/dist/antd.variable.min.css';
import '~/stylesheet/base.scss';

import { useContext, useEffect } from 'react';
import NotificationTask from '~/components/shared/_task/NotificationTask';
import Routers from '~/config/routes/Routers';
import { AuthContext } from '~/contexts/AuthContext';
import { useRouterContext } from '~/contexts/RouterContext';
import { BaseUtils } from '~/ultils/base-utils';
import { useNavigate } from 'react-router-dom';
import { TOKEN_KEY_STORE } from './contants/commons';
import Loading from './components/loading/Loading';
import GlobalContext, { useGlobalContext } from './contexts/GlobalContext';

declare var window: any;

ConfigProvider.config({
  theme: {
    primaryColor: '#3280F6',
    infoColor: '#28a745',
    successColor: '#28a745',
  },
});

function App() {
  const { setIsAuth } = useContext(AuthContext);
  const { loading } = useGlobalContext();
  const { isPrivateRouter } = useRouterContext();
  const navigator = useNavigate();
  useEffect(() => {
    function event(evt: any) {
      setIsAuth(false);
      localStorage.removeItem(TOKEN_KEY_STORE);
      navigator('/login');
    }
    window.addEventListener('authentication', event, false);
    return () => {
      window.removeEventListener('authentication', event, false);
    };
  }, [setIsAuth, navigator]);

  useEffect(() => {
    BaseUtils.initConfigDate();
    BaseUtils.setDefaultTimeZone();
  }, []);

  return (
    <>
      <div className="App">
        <ConfigProvider
          form={{ requiredMark: false, colon: false }}
          input={{ autoComplete: 'off' }}
          renderEmpty={() => (
            <span className="text-gray-600">
              <StopOutlined /> Không có dữ liệu
            </span>
          )}
        >
          {loading && <Loading isProgress={false} />}
          <Routers />
        </ConfigProvider>
        {isPrivateRouter && <NotificationTask />}
      </div>
    </>
  );
}

export default App;
