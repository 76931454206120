import {
  DownOutlined,
  FileOutlined,
  HistoryOutlined,
  InfoCircleOutlined,
  PayCircleOutlined,
  PhoneOutlined,
  QrcodeOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Form,
  Input,
  InputNumber,
  Modal,
  Popover,
  Select,
  Space,
  Tag,
  Typography,
} from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import contact_customer_fail from '~/assets/images/contact_customer_fail.png'; 
import late_delivery from '~/assets/images/late_delivery.png';

import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect } from 'react';
import EmployeeDrawer from '~/components/shared/EmployeeDrawer';
import {
  HeavyProductOptions,
  OrderReasonCancel,
  PackageSize,
  ResetOrderStatusOptions,
  ShippingService,
  StatusOfOrder,
  mappingLabelOperaOrder,
  orderSorryVoucherReasonOptions,
} from '~/contants/order';
import { useGlobalContext } from '~/contexts/GlobalContext';
import { EMPTY } from '~/modules/operate-order/pages/OrderDetail/components/OrderDetailWrapper/OrderDetailWrapper';
import { OrderDetail } from '~/types/orderDetailType';
import VATFormDialog from './diaglog/VATFormDialog';
import QRCode from 'qrcode.react';

const { Paragraph, Text} = Typography;

type Props = {
  orderDetail?: OrderDetail;
  orderStatus?: any;
  MenuAction?: any;
  isOpenDialogCancel?: boolean;
  isShowEmployeeAssign?: boolean;
  loadingBookShipper: boolean;
  loadingCancel: boolean;
  onAssignOrder?: (value: any) => void;
  onShowEmployeeAssign?: (status: boolean) => void;
  onShowOrderHistory?: () => void;
  onApplyActionOrder?: (action: any) => void;
  onCancelOrder?: (value: any) => void;

  onCloseCancelOrder?: () => void;
  onChangeReasonCancel?: (value: OrderReasonCancel) => void;

  onCloseCancelShipper?: () => void;

  onCancelShipper?: (value: any) => void;
  isOpenDialogCancelShipper: boolean;

  isOpendBookShipperPopup?: boolean;
  onBookShipper: (packageSize: PackageSize) => void;
  onCloseBookShipperDialog: () => void;

  isOpenVATDialog?: boolean;
  onUpdateOrderVAT: (value: any) => void;
  onCloseVATDialog: () => void;
  onOpenVATDialog: () => void;
  isLoadingUpdateVAT: boolean;

  isOpenSorryVoucher?: boolean;
  loadingSorryVoucher?: boolean;
  onCreateSorryVoucher?: (values: any) => void;
  onCloseSorryVoucherDialog?: () => void;

  onResetOrder: (status: StatusOfOrder) => void;
  onCloseResetOrder?: () => void;
  isOpenResetOrder?: boolean;
  isLoandingResetOrder?: boolean;

  isOpenCallCustomerFail?: boolean;
  onAddOrderLogByCSCallCustomerFail?: ({note}: {note: string}) => void;
  onCloseCallCustomerFail?: () => void;
  isLoadingAddOrderLogByCSCallCustomerFail?: boolean;

  isOpenSendZNSMessage?: boolean;
  onSendZNSMessage?: (template: "LATE_DELIVERY" | "CONTACT_CUSTOMER_FAIL") => void;
  onCloseSendZNSMessage?: () => void;
  isLoadingSendZNSMessage?: boolean;
  isSuccessSendZNSMessage?: boolean;
};

const DialogCallCustomerFail = ({ isModalOpen, onOk, onCancel, onChangeReasonCancel, loading }: any) => {
  const [form] = Form.useForm<{ note: any }>();

  return (
    <Modal
      footer={false}
      centered
      title={false}
      visible={isModalOpen}
      width={650}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onOk}
        // onFinishFailed={onFinishFailed}
        form={form}
        autoComplete="off"
      >
        <div className="flex flex-col items-center gap-3 ">
          <InfoCircleOutlined style={{ fontSize: 27, color: 'orange' }} />
          <span className="font-medium text-center">Ghi nhận lý do liên hệ khách thất bại</span>
          <Form.Item name="note" noStyle>
            <TextArea placeholder="Nhập lý do" />
          </Form.Item>
          <div className="flex gap-2 mt-5 m-auto">
            <Button onClick={onCancel}>Trở lại</Button>
            <Button loading={loading} htmlType="submit" type="primary">
              Xác nhận
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};


const DialogCancelOrder = ({ isModalOpen, onOk, onCancel, onChangeReasonCancel, loading }: any) => {
  const [form] = Form.useForm<{ cancelCode: any; cancelDesc: string }>();

  const { orderCancelCodes } = useGlobalContext();

  useEffect(() => {
    form.setFieldsValue({
      cancelCode: OrderReasonCancel.CANCEL_BY_STORE_OUT_OF_STOCK,
      cancelDesc: '',
    });
  }, []);

  return (
    <Modal
      footer={false}
      centered
      title={false}
      visible={isModalOpen}
      width={650}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onOk}
        // onFinishFailed={onFinishFailed}
        form={form}
        autoComplete="off"
      >
        <div className="flex flex-col items-center gap-3 ">
          <InfoCircleOutlined style={{ fontSize: 27, color: 'orange' }} />
          <span className="font-medium text-center">Bạn có muốn huỷ đơn này</span>
          <Form.Item name="cancelCode" noStyle>
            <Select
              fieldNames={{
                value: 'id',
                label: 'name',
              }}
              className="w-100"
              options={orderCancelCodes}
              onChange={onChangeReasonCancel}
            />
          </Form.Item>
          <Form.Item name="cancelDesc" noStyle>
            <TextArea placeholder="Nhập lý do" />
          </Form.Item>
          <div className="flex gap-2 mt-5 m-auto">
            <Button onClick={onCancel}>Trở lại</Button>
            <Button loading={loading} htmlType="submit" type="primary">
              Xác nhận
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

const DialogSorryVoucher = ({ isModalOpen, onOk, onCancel, loading }: any) => {
  const [form] = Form.useForm<{ quantity: number }>();

  const reason = useWatch('reason', form);

  return (
    <Modal
      footer={false}
      centered
      visible={isModalOpen}
      width={550}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
      title="Chọn số lượng sorry vouchers"
    >
      <Form
        name="basic"
        initialValues={{ quantity: 1 }}
        onFinish={onOk}
        form={form}
        autoComplete="off"
      >
        <div className="flex flex-col  gap-3 ">
          <span className="">
            Bạn có thể gửi ít nhất 1 voucher [Mã Voucher] trị giá 50k cho khách
          </span>
          <Form.Item name="quantity" noStyle>
            <InputNumber min={1} className="w-100" placeholder="Nhập số lượng voucher" />
          </Form.Item>
          <Form.Item name="reason" noStyle>
            <Select
              allowClear
              placeholder="Vui lòng chọn lý do"
              className="w-100"
              options={orderSorryVoucherReasonOptions}
            />
          </Form.Item>
          {reason === -1 && (
            <Form.Item name="desc" noStyle>
              <Input placeholder="Nhập lý do" className="w-100" />
            </Form.Item>
          )}
          <div className="flex gap-2 mt-5 m-auto">
            <Button onClick={onCancel}>Trở lại</Button>
            <Button loading={loading} htmlType="submit" type="primary">
              Xác nhận
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

const DialogCancelShipper = ({ isModalOpen, onOk, onCancel, loading }: any) => {
  const [form] = Form.useForm<{ cancelCode: any; cancelDesc: string }>();
  useEffect(() => {
    form.setFieldsValue({
      cancelCode: OrderReasonCancel.CANCEL_BY_STORE_OUT_OF_STOCK,
      cancelDesc: '',
    });
  }, []);

  return (
    <Modal
      footer={false}
      centered
      title={false}
      visible={isModalOpen}
      width={515}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={(values) => onOk(values?.cancelDesc)}
        // onFinishFailed={onFinishFailed}
        form={form}
        autoComplete="off"
      >
        <div className="flex flex-col items-center gap-3 ">
          <InfoCircleOutlined style={{ fontSize: 27, color: 'orange' }} />
          <span className="font-medium">Bạn có muốn huỷ book tài xế?</span>
          <Form.Item
            name="cancelDesc"
            className="w-100"
            rules={[{ required: true, message: 'Nhập lý do' }]}
          >
            <TextArea placeholder="Nhập lý do" />
          </Form.Item>
          <div className="flex gap-2 mt-5">
            <Button onClick={onCancel}>Trở lại</Button>
            <Button loading={loading} htmlType="submit" type="primary">
              Xác nhận
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

const BookShipperDialog = ({ isModalOpen, onOk, onCancel, loading }: any) => {
  const [form] = Form.useForm<{ packageSize: PackageSize; serviceId: ShippingService }>();

  return (
    <Modal
      footer={false}
      centered
      title={false}
      visible={isModalOpen}
      width={515}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
    >
      <Form
        name="basic"
        initialValues={{ packageSize: 'STANDARD', serviceId: 'SGN-BIKE' }}
        onFinish={(values) => onOk({ ...values })}
        form={form}
        layout="vertical"
        autoComplete="off"
      >
        <div className="flex flex-col gap-2">
          <div className="flex justify-center flex-1">
            <InfoCircleOutlined style={{ fontSize: 27, color: 'orange' }} />
          </div>
          <span className="font-medium flex-col flex text-center items-center">
            Bạn có muốn book tài xế?
          </span>
          <div>
            <label className="font-medium mb-2">Package Size</label>
            <Form.Item name="packageSize" className="w-100 border" noStyle>
              <Select
                defaultValue={'STANDARD'}
                placeholder="Chọn hàng cồng kềnh"
                style={{ width: '100%' }}
              >
                {HeavyProductOptions.map((heavyProductOptions) => (
                  <Select.Option value={heavyProductOptions.value}>
                    {heavyProductOptions.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <label className="font-medium mb-2">ServiceID</label>
            <Form.Item name="serviceId" className="w-100 border" noStyle>
              <Select
                defaultValue={'SGN-BIKE'}
                placeholder="Chọn hàng cồng kềnh"
                style={{ width: '100%' }}
              >
                <Select.Option value="SGN-BIKE">
                  <span className="font-bold">Tài xế Ahamove (SGN-BIKE)</span>
                </Select.Option>
                <Select.Option value="VNM-PARTNER-KFM-FT">
                  <span className="font-bold">Tài xế nội bộ (VNM-PARTNER-KFM-FT)</span>
                </Select.Option>
                <Select.Option value="VNM-PARTNER-KFM-PT">
                  <span className="font-bold">Tài xế siêu thị (VNM-PARTNER-KFM-PT)</span>
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div>
            <label className="font-medium mb-2">Tiền Tip</label>
            <Form.Item name="tip" className="w-100 border" noStyle>
              <Select allowClear placeholder="Tiền Tip" style={{ width: '100%' }}>
                <Select.Option value={5000}>
                  <span className="font-bold">5.000đ</span>
                </Select.Option>
                <Select.Option value={10000}>
                  <span className="font-bold">10.000đ</span>
                </Select.Option>
                <Select.Option value={20000}>
                  <span className="font-bold">20.000đ</span>
                </Select.Option>
                <Select.Option value={30000}>
                  <span className="font-bold">30.000đ</span>
                </Select.Option>
                <Select.Option value={50000}>
                  <span className="font-bold">50.000đ</span>
                </Select.Option>
                <Select.Option value={100000}>
                  <span className="font-bold">100.000đ</span>
                </Select.Option>
              </Select>
            </Form.Item>
          </div>
          <div>
            <label className="font-medium mb-2">Mã khuyến mãi</label>
            <Form.Item name="promotionCode" className="w-100 border" noStyle>
              <Input placeholder="Mã khuyến mãi" allowClear />
            </Form.Item>
          </div>
          <div className="flex justify-center gap-2 mt-5">
            <Button onClick={onCancel}>Trở lại</Button>
            <Button loading={loading} htmlType="submit" type="primary">
              Xác nhận
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

const ResetOrderDialog = ({ isModalOpen, onOk, onCancel, loading }: any) => {
  const [form] = Form.useForm<{ status: number }>();

  return (
    <Modal
      footer={false}
      centered
      visible={isModalOpen}
      width={450}
      onCancel={onCancel}
      closable={false}
      maskClosable={false}
      title="Reset trạng thái đơn hàng"
    >
      <Form name="basic" onFinish={onOk} form={form} autoComplete="off">
        <div className="flex flex-col  gap-3 ">
          <Form.Item name="status" noStyle>
            <Select
              allowClear
              placeholder="Vui lòng trạng thái"
              className="w-100"
              options={ResetOrderStatusOptions}
            />
          </Form.Item>
          <div className="flex gap-2 mt-5 m-auto">
            <Button onClick={onCancel}>Trở lại</Button>
            <Button loading={loading} htmlType="submit" type="primary">
              Xác nhận
            </Button>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

const SendZNSMessageDialog = ({ isModalOpen, onOk, onCancel, loading }: any) => {
  const [form] = Form.useForm<{ template: "LATE_DELIVERY" | "CONTACT_CUSTOMER_FAIL" }>();
  const { znsTemplates } = useGlobalContext()
  
  const template = Form.useWatch('template', form)

  return <Modal
    footer={false}
    centered
    visible={isModalOpen}
    width={450}
    onCancel={onCancel}
    closable={false}
    maskClosable={false}
  >
    <Form initialValues={{ template: "LATE_DELIVERY" }} name="basic" onFinish={onOk} form={form} autoComplete="off">
      <div className="flex justify-center flex-1 mb-2">
        <InfoCircleOutlined style={{ fontSize: 27, color: 'orange' }} />
      </div>
      <span className="font-medium flex-col flex text-center items-center mb-3">
        Gửi ZNS message
      </span>
      <div className="flex flex-col  gap-3 ">
        <Form.Item name="template" noStyle>
          <Select
            allowClear
            placeholder="Vui lòng trạng thái"
            className="w-100"
            fieldNames={{
              value: 'id',
              label: 'name',
            }}
            options={znsTemplates}
          />
        </Form.Item>
        <img src={template === "CONTACT_CUSTOMER_FAIL" ? contact_customer_fail : late_delivery} />

        <div className="flex gap-2 mt-5 m-auto">
          <Button onClick={onCancel}>Trở lại</Button>
          <Button loading={loading} htmlType="submit" type="primary">
            Xác nhận
          </Button>
        </div>
      </div>
    </Form>
  </Modal>;
};

const OrderDetailAction: React.FC<Props> = ({
  orderDetail,
  MenuAction,
  orderStatus,
  isOpenDialogCancel,
  isShowEmployeeAssign,
  loadingBookShipper,
  loadingCancel,
  onAssignOrder,
  onShowEmployeeAssign,
  onApplyActionOrder,
  onShowOrderHistory,
  onCancelOrder,

  onCloseCancelOrder,
  onChangeReasonCancel,

  onCloseCancelShipper,
  onCancelShipper,
  isOpenDialogCancelShipper,

  isOpendBookShipperPopup,
  onBookShipper,
  onCloseBookShipperDialog,

  isOpenVATDialog,
  onUpdateOrderVAT,
  onCloseVATDialog,
  onOpenVATDialog,
  isLoadingUpdateVAT,

  isOpenSorryVoucher,
  loadingSorryVoucher,
  onCreateSorryVoucher,
  onCloseSorryVoucherDialog,

  onResetOrder,
  onCloseResetOrder,
  isOpenResetOrder,
  isLoandingResetOrder,

  isOpenCallCustomerFail,
  onAddOrderLogByCSCallCustomerFail,
  onCloseCallCustomerFail,
  isLoadingAddOrderLogByCSCallCustomerFail,

  isOpenSendZNSMessage,
  onSendZNSMessage,
  onCloseSendZNSMessage,
  isLoadingSendZNSMessage,
  isSuccessSendZNSMessage,
}) => {
  const { header } = orderDetail || {};
  const { vat } = header || {};

  const isVAT = Boolean(Object.values(vat || {}).join(''));

  return (
    <>
      <DialogSorryVoucher
        onOk={onCreateSorryVoucher}
        onCancel={onCloseSorryVoucherDialog}
        onChangeReasonCancel={onChangeReasonCancel}
        isModalOpen={isOpenSorryVoucher}
        loading={loadingSorryVoucher}
      />

      {/* Reset order */}
      <ResetOrderDialog
        onOk={onResetOrder}
        onCancel={onCloseResetOrder}
        onChangeReasonCancel={onChangeReasonCancel}
        isModalOpen={isOpenResetOrder}
        loading={isLoandingResetOrder}
      />

      {/* Cancel dialog */}
      <DialogCancelOrder
        onOk={onCancelOrder}
        onCancel={onCloseCancelOrder}
        onChangeReasonCancel={onChangeReasonCancel}
        isModalOpen={isOpenDialogCancel}
        loading={loadingCancel}
      />

      <DialogCancelShipper
        onOk={onCancelShipper}
        onCancel={onCloseCancelShipper}
        isModalOpen={isOpenDialogCancelShipper}
        loading={loadingBookShipper}
      />
      <EmployeeDrawer
        visible={isShowEmployeeAssign}
        onSelectEmployee={onAssignOrder}
        onClose={() => onShowEmployeeAssign?.(false)}
      />
      <BookShipperDialog
        onOk={onBookShipper}
        onCancel={onCloseBookShipperDialog}
        isModalOpen={isOpendBookShipperPopup}
        loading={loadingBookShipper}
      />
      <DialogCallCustomerFail
        onOk={onAddOrderLogByCSCallCustomerFail}
        onCancel={onCloseCallCustomerFail}
        isModalOpen={isOpenCallCustomerFail}
        loading={isLoadingAddOrderLogByCSCallCustomerFail}
      />
      <VATFormDialog
        onOk={onUpdateOrderVAT}
        onCancel={onCloseVATDialog}
        isModalOpen={isOpenVATDialog}
        loading={isLoadingUpdateVAT}
        orderDetail={orderDetail}
      />
      <SendZNSMessageDialog
        onOk={onSendZNSMessage}
        onCancel={onCloseSendZNSMessage}
        isModalOpen={isOpenSendZNSMessage}
        loading={isLoadingSendZNSMessage}
        isSuccess={isSuccessSendZNSMessage}
      />
      <div className="flex justify-between w-100 items-start flex-wrap gap-5">
        <div className="flex flex-col flex-wrap">
          <div className="flex items-center flex-wrap">
            <h1 className="text-lg mb-0 mr-3 flex items-center flex-wrap">
            {orderDetail?.header?.code}
            </h1>
            <Tag color={mappingLabelOperaOrder?.[orderDetail?.header?.status as any]?.color || '#dfdfdf'}>{orderStatus}</Tag>
            <Text copyable={{ text: orderDetail?.header?.code }}  strong className="mb-0" />
            {isVAT && (
              <Tag color="geekblue" className="pl-0">
                <Paragraph
                  copyable={{
                    text: Object.values(vat || {}).join('\n'),
                    tooltips: ['Nhấn để copy', 'Copy thành công'],
                  }}
                  className="mb-0 pl-0"
                >
                  VAT
                </Paragraph>
              </Tag>
            )}
            <Popover 
              placement='right'
              content={<div className='flex flex-col gap-2 items-center justify-center'>
              <QRCode value={orderDetail?.header?.code || ''} />
              <span className='text-sm'>{orderDetail?.header?.code}</span>
            </div>
            } trigger="click">
              <Button type="link" size="large" icon={<QrcodeOutlined />} />
            </Popover>
          </div>
          <div className="mt-2" style={{ marginBottom: '-15px' }}>
            <div>
              <div className="flex flex-wrap">
                <span className="font-medium">CS</span>
                &nbsp;
                <b>{orderDetail?.header?.assignee?.name || EMPTY}</b>
                {orderDetail?.header?.assignee?.phone && (
                  <>
                    &nbsp;{orderDetail?.header?.assignee?.phone || EMPTY}
                    <a href={`tel:${orderDetail?.header?.assignee?.phone}`} className="ml-1">
                      <PhoneOutlined />
                    </a>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center flex-wrap gap-y-2">
          <Button
            type="link"
            className="pl-0"
            onClick={onOpenVATDialog}
            icon={
              <PayCircleOutlined
                style={!Object.values(vat || {}).join('') ? { color: '#a0aec0' } : {}}
              />
            }
          >
            VAT
          </Button>
          <Button
            onClick={() => window.open(`/export/invoice/${orderDetail?.header?.code}`)}
            type="link"
            icon={<FileOutlined />}
          >
            Hoá đơn
          </Button>
          <Button onClick={onShowOrderHistory} type="link" icon={<HistoryOutlined />}>
            Lịch sử đơn hàng
          </Button>
          <Dropdown
            overlayStyle={{ width: 250 }}
            overlay={
              <MenuAction
                {...{
                  onApplyActionOrder: (action) => onApplyActionOrder?.(action),
                  orderDetail,
                }}
              />
            }
          >
            <Button>
              <Space>
                Thao tác
                <DownOutlined />
              </Space>
            </Button>
          </Dropdown>
        </div>
      </div>
    </>
  );
};

export default React.memo(OrderDetailAction);
