import React from 'react';
import { Link } from 'react-router-dom';
import { Customer } from '~/types/orderDetailType';

const CustomerLink = ({ customer }: { customer?: Partial<Customer> }): any => {
  if (!customer) return '--';
  return (
    <Link
      style={{ whiteSpace: 'nowrap' }}
      target="_blank"
      to={`/customer/customer-detail/${customer?.phone}`}
      className="truncate"
    >
      {customer?.name}
    </Link>
  );
};

export default CustomerLink;
